var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_vm.$options.components['advertisement-top-banner-text'] ? [_c('advertisement-top-banner-text')] : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm.$options.components['health-care-category'] ? [_c('health-care-category')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('span', {
    staticClass: "text-grey1 mt-1 ml-0"
  }, [_vm._v("Order Medicine > Order With Prescription ")]), _c('b-card', {
    staticClass: "mt-4"
  }, [_c('h4', [_vm._v(" Please upload images of valid prescription from your doctor. ")]), _c('b-form-file', {
    attrs: {
      "state": Boolean(_vm.file1),
      "placeholder": "Please upload prescription here...",
      "drop-placeholder": "Drop file here..."
    },
    on: {
      "change": _vm.readLogo
    }
  }), _vm.file1 ? _c('div', [_c('hr'), _c('div', {
    staticClass: "text-right"
  }, [_c('b-button', {
    staticClass: "rounded-circle tx-20",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        _vm.file1 = '';
      }
    }
  }, [_vm._v("×")])], 1), _vm.file1 ? _c('div', [_c('b-img', {
    staticClass: "my-2 image",
    attrs: {
      "src": _vm.file1,
      "center": ""
    }
  })], 1) : _vm._e(), _c('hr')]) : _vm._e(), _c('b-form-group', {
    staticClass: "my-3",
    attrs: {
      "label": "Select Items"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-radio-group', {
          staticClass: "text-grey1",
          attrs: {
            "id": "radio-group-1",
            "options": _vm.options,
            "aria-describedby": ariaDescribedby,
            "name": "radio-options"
          },
          model: {
            value: _vm.selected,
            callback: function callback($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }])
  }), _vm.selected == 'specificItem' ? _c('b-row', {
    staticClass: "my-3"
  }, [_c('b-col', {
    staticClass: "text-grey1",
    attrs: {
      "md": "2"
    }
  }, [_vm._v("Enter Medicine Sequence :")]), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "1,3,4"
    },
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  })], 1)], 1) : _vm._e(), _c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-1",
      "name": "checkbox-1",
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }, [_vm._v(" Call Back Required ")]), _c('div', {
    staticClass: "text-right"
  }, [_c('b-button', {
    staticClass: "btn-color",
    on: {
      "click": function click($event) {
        _vm.$router.push({
          name: 'pharmacy-listing',
          params: {
            orderData: _vm.orderPrescriptionData()
          }
        });
      }
    }
  }, [_vm._v("Select Pharmacy")])], 1)], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }