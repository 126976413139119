<template>
  <b-container>
    <template
      class="mt-0"
      v-if="$options.components['advertisement-top-banner-text']"
    >
      <advertisement-top-banner-text />
    </template>
    <b-row>
      <b-col cols="3">
        <template v-if="$options.components['health-care-category']">
          <health-care-category />
        </template>
      </b-col>

      <b-col cols="9">
        <span class="text-grey1 mt-1 ml-0"
          >Order Medicine > Order With Prescription
        </span>
        <b-card class="mt-4">
          <h4>
            Please upload images of valid prescription from your doctor.
          </h4>
          <b-form-file
            @change="readLogo"
            :state="Boolean(file1)"
            placeholder="Please upload prescription here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
          <div v-if="file1">
            <hr />
            <div class="text-right">
              <b-button
                variant="danger"
                class="rounded-circle tx-20"
                @click="file1 = ''"
                >&times;</b-button
              >
            </div>
            <div v-if="file1">
              <b-img :src="file1" center class="my-2 image" />
            </div>
            <hr />
          </div>

          <b-form-group
            label="Select Items"
            v-slot="{ ariaDescribedby }"
            class="my-3"
          >
            <b-form-radio-group
              class="text-grey1"
              id="radio-group-1"
              v-model="selected"
              :options="options"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>

          <b-row class="my-3" v-if="selected == 'specificItem'">
            <b-col md="2" class="text-grey1">Enter Medicine Sequence :</b-col>
            <b-col>
              <b-form-input
                v-model="selectedItem"
                placeholder="1,3,4"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-form-checkbox
            id="checkbox-1"
            v-model="status"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
            Call Back Required
          </b-form-checkbox>
          <div class="text-right">
            <b-button
              class="btn-color"
              @click="
                $router.push({
                  name: 'pharmacy-listing',
                  params: { orderData: orderPrescriptionData() },
                })
              "
              >Select Pharmacy</b-button
            >
          </div>
        </b-card></b-col
      >
    </b-row>
  </b-container>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      file1: null,
      selected: "allItems",
      options: [
        { text: "All Items", value: "allItems" },
        { text: "Specific Item", value: "specificItem" },
      ],
      status: false,
      selectedItem: "",
      orderPrescription: "",
    };
  },
  methods: {
    // upload images to the S3 bucket
    readLogo(e) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.uploadImage(reader.result);
      };
      reader.onerror = (err) => {
        console.error("reader : ", err);
      };
    },
    showCustomToast() {
      Vue.toasted.show("Uploading...", {
        type: "info",
        theme: "toasted-primary",
        singleton: true,
        duration: 1000000,
      });
    },
    clearCustomToast() {
      Vue.toasted.clear();
    },
    async uploadImage(image) {
      let dataobj = {
        image: image,
        key: (
          Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
        ).toLowerCase(),
        isPublic: true,
        replaceExisting: true,
        basePath: "tenant",
      };
      this.showCustomToast();
      await this.$store
        .dispatch("healthCare/uploadImageToStorage", dataobj)
        .then((res) => {
          if (res) {
            this.clearCustomToast();
            this.file1 = res.data.data.fileUrl;
          }
        })
        .catch((error) => {
          console.log(error);
          this.clearCustomToast();
          this.$toasted.error("Image not uploaded..please try again!");
        });
    },
    orderPrescriptionData() {
      this.orderPrescription = {
        attachment: this.file1,
        selectedType: "",
        callBack: this.status,
      };
      if (this.selected === "specificItem") {
        this.orderPrescription.selectedType = this.selectedItem;
      } else {
        this.orderPrescription.selectedType = this.selected;
      }
      return this.orderPrescription;
    },
  },
};
</script>

<style>
.text-grey1 {
  color: #aaa;
  background-color: transparent;
}
.image {
  height: 22%;
  width: 55%;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #fe7b08 !important;
  background-color: #fe7b08 !important;
}
.btn-color {
  background-color: #fe7b08 !important;
  color: #fff !important;
}
</style>
